import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Navbar from './NavBar';
import QuotationSummary from './QuotationSummary';
import AddonsSection from './addons';
import '../style/BuyPlan.css';
import pdfimage from '../images/pdf-img.jpeg';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import stamp from '../images/stamp.jpeg';
import 'react-datepicker/dist/react-datepicker.css';
const BuyPlan = () => {
  const openInNewTab = () => {
    window.open(window.location.href, '_blank');
  };
  const showAlert = (message) => {
    const alertBox = document.createElement('div');
    alertBox.className = 'alert-box';
    alertBox.textContent = message;
    document.body.appendChild(alertBox);
    setTimeout(() => {
      alertBox.remove();
    }, 2000);
  };

  const [sports, setSports] = useState([]);
  const [centers, setCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState('');
  const [filteredSports, setFilteredSports] = useState([]);
  const [rows, setRows] = useState([{ sport: '', date: '', startTime: '', endTime: '' }]);
  const [selectedSport, setSelectedSport] = useState('');
  const [numberOfCourts, setNumberOfCourts] = useState(0);
  const [maxCourts, setMaxCourts] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [quotationData, setQuotationData] = useState({ products: [], selectedAddOns: [] });
  const [showSummary, setShowSummary] = useState(false);
  const [maxCourtsError, setMaxCourtsError] = useState('');
  const centerInfo = {
    'Machaxi Badminton Centre Munnekollal': {
      helpline: '8792920136',
      location: 'https://maps.app.goo.gl/bhTeTr91aGB7kmSp8'
    },
    'Machaxi Sumukha Badminton Centre': {
      helpline: '8050354469',
      location: 'https://maps.app.goo.gl/jq3Pnqqumrm6hCCF8'
    },
    'Machaxi AJ Badminton Centre': {
      helpline: '8792920160',
      location: 'https://maps.app.goo.gl/nX81MJDMmHv7J7Fz6'
    },
    'Machaxi J Sports': {
      helpline: '7204986282',
      location: 'https://maps.app.goo.gl/ThgP9HyaFCta4SG48'
    },
    'Machaxi Ahalya Sports Centre': {
      helpline: '8792920164',
      location: 'https://maps.app.goo.gl/6QbhGfLZPGxcfcWg9'
    },
    'Machaxi Play9 Sports Centre': {
      helpline: '8050354468',
      location: 'https://maps.app.goo.gl/qzUvRkfUuD1tpddd9'
    },
    'Machaxi Cross Court Badminton Centre': {
      helpline: '8050354467',
      location: 'https://maps.app.goo.gl/bALmDqxCcrUATfgo7'
    },
    'Machaxi Maagniv Badminton Centre': {
      helpline: '7204986284',
      location: 'https://maps.app.goo.gl/2YfptN8vXLGQ8YGH7'
    },
    'Machaxi Pragathi Badminton Centre': {
      helpline: '8050354465',
      location: 'https://maps.app.goo.gl/JkToRsD4B9k12ptC6'
    },
    'Machaxi Nadando Swimming Centre': {
      helpline: '8050354471',
      location: 'https://maps.app.goo.gl/R4SuJHmvsvBowpqj6'
    },
    'Machaxi Milan Sports Centre': {
      helpline: '8050354463',
      location: 'https://maps.app.goo.gl/z3hWKJzviPYs72xJ8'
    },
    'Machaxi Sportexx Sports Centre': {
      helpline: '7204986285',
      location: 'https://maps.app.goo.gl/LayYEYMDEvdvWW288'
    },

    'Machaxi Ready to Rally Sports Centre': {
      helpline: '8792920133',
      location: 'https://maps.app.goo.gl/hb3WRaLkF1LSK5wV6'
    },
    'Machaxi - AJ Hoodi': {
      helpline: '8792920162',
      location: 'https://maps.app.goo.gl/svKvyZzF1dTiT3sh8'
    },
    'Machaxi - Lets Play': {
      helpline: '8050354470',
      location: 'https://maps.app.goo.gl/cgq17ZX21Re9AsnD6'
    },
    'Machaxi Playtm': {
      helpline: '8050354464',
      location: 'https://maps.app.goo.gl/cofwFyG4rtXNBR2TA'
    },
    'Machaxi J C Sports Centre': {
      helpline: '8050354462',
      location: 'https://maps.app.goo.gl/uRmeP7EYhwkAwSM47'
    },
    'Machaxi Sports Centre Whitefield': {
      helpline: '8050354472',
      location: 'https://maps.app.goo.gl/qzUvRkfUuD1tpddd9'
    },
    // Add more centers as needed
  };

  useEffect(() => {
    axios.get('https://admin.machaxi.com/summer-api/get-data', {
      headers: {
        'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg'
      }
    })
      .then((response) => {
        const { allPlans } = response.data;
        const uniqueCenters = [...new Set(allPlans.map(item => item.academy_name))];
        setCenters(uniqueCenters);
        setSports(allPlans);
        console.log(response.data)
      })
      .catch((error) => {
      });
  }, []);
  useEffect(() => {
    if (selectedCenter) {
      const filtered = sports.filter(
        sport => sport.academy_name.toLowerCase() === selectedCenter.toLowerCase()
      );
      console.log("Filtered Sports for Center:", filtered); // Log filtered sports
      setFilteredSports(filtered);

      // Log max_avail_arenas for all sports
      console.log("Max Available Arenas for All Sports:");
      filtered.forEach(sport => {
        console.log(`Sport: ${sport.sport_name}, Max Arenas: ${sport.max_avail_arenas}`);
      });
    }
  }, [selectedCenter, sports]);

  useEffect(() => {
    if (selectedSport.toLowerCase() === 'badminton' || selectedSport === 'TT') {
      const selectedCenterData = filteredSports.filter(sport => sport.sport_name.toLowerCase() === selectedSport.toLowerCase());
      const max = Math.max(...selectedCenterData.map(center => center.max_avail_arenas || 0));
      setMaxCourts(max);
      setNumberOfCourts(Math.min(numberOfCourts, max));
    } else {
      setMaxCourts(0);
      setNumberOfCourts(0);
    }
  }, [selectedSport, filteredSports]);

  const [dateForms, setDateForms] = useState([
    {
      selectedDate: '',
      selectedCenter: '',
      selectionType: 'company',
      sportForms: [
        {
          selectedSport: '',
          numberOfCourts: 1,
          hours: 1,
        },
      ],
      filteredSports: [], // Add this state to each date form
      showAddAnotherSportButton: false, // Add this state to each date form
      error: '',
    },
  ]);

  const [addonFormData, setAddonFormData] = useState([]);

  const handleAddOnFormChange = (addOns) => {
    setQuotationData((prev) => ({ ...prev, selectedAddOns: addOns }));
    setAddonFormData(addOns);
  };
  const [showAddAnotherSport, setShowAddAnotherSport] = useState(false);

  const handleSportSelection = (sport, dateIndex, sportIndex) => {
    const updatedDateForms = [...dateForms];
    const dateForm = updatedDateForms[dateIndex];
    const sportForm = dateForm.sportForms[sportIndex];

    sportForm.selectedSport = sport;

    if (sport.toLowerCase() !== 'badminton' && sport.toLowerCase() !== 'tt') {
      sportForm.numberOfCourts = 1;
    } else {
      const selectedSportData = dateForm.filteredSports.find(
        (filteredSport) => filteredSport.sport_name.toLowerCase().trim() === sport.toLowerCase().trim()
      );
      if (selectedSportData) {
        const max = selectedSportData.max_avail_arenas || 0;
        sportForm.maxCourts = max;
        sportForm.numberOfCourts = 1;
      }
    }

    setDateForms(updatedDateForms);
    recalculateTotalPrice();
  };



  const [showAddAnotherSportButton, setShowAddAnotherSportButton] = useState(true);

  const handleCenterChange = (e) => {
    const center = e.target.value;
    console.log("Selected Center:", center); // Log selected center
    setSelectedCenter(center);
    setRows([{ sport: '', date: '', startTime: '', endTime: '' }]);
  };


  const handleCourtCountChange = (increment, dateIndex, sportIndex) => {
    setDateForms(prevDateForms => {
      const updatedDateForms = [...prevDateForms];
      const dateForm = updatedDateForms[dateIndex];
      const sportForm = dateForm.sportForms[sportIndex];
      const newCount = sportForm.numberOfCourts + increment;

      // Prevent negative counts and check against max courts
      if (newCount < 0) {
        sportForm.numberOfCourts = 0; // Prevent negative counts
        setErrorMessage('Court count cannot be less than 0');
      } else if (newCount > sportForm.maxCourts) {
        // If the new count exceeds maxCourts, do not allow increment
        sportForm.numberOfCourts = sportForm.maxCourts; // Set to max if exceeded
        setMaxCourtsError(`Maximum courts available for this center is ${sportForm.maxCourts}`);
        return updatedDateForms; // Return early to prevent further updates
      } else {
        sportForm.numberOfCourts = newCount; // Set to new count
        setMaxCourtsError(''); // Clear error if within limits
      }

      // console.log('Updated numberOfCourts:', sportForm.numberOfCourts);

      return updatedDateForms;
    });

    // After updating court count, recalculate the total price
    recalculateTotalPrice();
  };


  // Log the updated quotation data before setting it to state
  const recalculateTotalPrice = () => {
    const updatedQuotationData = {};

    dateForms.forEach((dateForm, dateIndex) => {
      dateForm.sportForms.forEach((sportForm, sportIndex) => {
        if (sportForm.selectedSport) {
          // Find the selected sport data from the corresponding date form's filteredSports
          const selectedSportData = dateForm.filteredSports.find(sport =>
            sport.sport_name.toLowerCase().trim() === sportForm.selectedSport.toLowerCase().trim()
          );

          // Check if we successfully found the sport data
          if (selectedSportData) {
            const unitPrice = selectedSportData.price_per_arena || 0;
            const gst = selectedSportData.GST || 0;

            // Calculate the total price for this sport
            const gstAmount = (unitPrice * gst / 100) * sportForm.numberOfCourts * sportForm.hours;
            const totalPrice = (unitPrice * sportForm.numberOfCourts * sportForm.hours) + gstAmount;

            // Update the accumulated data
            if (updatedQuotationData[sportForm.selectedSport]) {
              // If the sport is already in the quotation, accumulate the values
              updatedQuotationData[sportForm.selectedSport].totalPrice += totalPrice;
              updatedQuotationData[sportForm.selectedSport].hours += sportForm.hours;
              updatedQuotationData[sportForm.selectedSport].instances.push({ dateIndex, sportIndex });
            } else {
              // Otherwise, add a new entry for the sport
              updatedQuotationData[sportForm.selectedSport] = {
                product: sportForm.selectedSport,
                unitPrice: unitPrice,
                gst: gst,
                gstAmount: gstAmount,
                totalPrice: totalPrice,
                hours: sportForm.hours,
                instances: [{ dateIndex, sportIndex }],
              };
            }
          } else {
            // console.error(`Selected sport data not found for sport: ${sportForm.selectedSport}`);
          }
        }
      });
    });

    // Update the quotation data state
    setQuotationData({ products: Object.values(updatedQuotationData) });
  };


  const sportSliderRef = useRef(null);
  const handleAddAnotherSport = (dateIndex) => {
    const updatedDateForms = [...dateForms];
    const dateForm = updatedDateForms[dateIndex];
    const lastSportForm = dateForm.sportForms[dateForm.sportForms.length - 1];

    // Check if a sport has been selected in the last sport form
    if (!lastSportForm.selectedSport) {
      showAlert('Please select a sport before adding another.'); // Pop-up alert
      return; // Prevent adding another sport form
    }

    // Check if all sports have been selected
    const selectedSports = dateForm.sportForms.map(form => form.selectedSport.toLowerCase());
    const availableSports = dateForm.filteredSports.map(sport => sport.sport_name.toLowerCase());
    const unselectedSports = availableSports.filter(sport => !selectedSports.includes(sport));

    // Show alert if all sports have been selected
    if (unselectedSports.length === 0) {
      showAlert('All sports have been selected.'); // Pop-up alert
      return; // Prevent adding another sport form
    }

    // Add a new sport form only if there are unselected sports remaining
    dateForm.sportForms.push({
      selectedSport: '', // Reset sport type for new form
      numberOfCourts: 1, // Default number of courts
      hours: 1, // Default hours
    });

    // Hide the "Add Another Sport" button if all sports have been selected
    dateForm.showAddAnotherSportButton = unselectedSports.length > 0;

    // Update the state with the new sport form
    setDateForms(updatedDateForms);
  };

  const availableSportsForCenter = (center) => {
    return filteredSports.filter(sport => {
      return sport.academy_name.toLowerCase() === center.toLowerCase() && !removedSports.includes(sport.sport_name.toLowerCase());
    });
  };

  const handleDateFormChange = (dateIndex, field, value) => {
    const updatedDateForms = [...dateForms];
    updatedDateForms[dateIndex][field] = value; // Update the specified field

    // If the field is selectedCenter, update the filteredSports
    if (field === 'selectedCenter') {
      const filtered = sports.filter(sport => sport.academy_name.toLowerCase() === value.toLowerCase());
      updatedDateForms[dateIndex].filteredSports = filtered;
    }

    setDateForms(updatedDateForms);
    setShowAddAnotherSportButton(true);
  };



  const increaseCourt = (dateIndex, sportIndex) => {
    const updatedDateForms = [...dateForms];
    const dateForm = updatedDateForms[dateIndex];
    const sportForm = dateForm.sportForms[sportIndex];
    const max = dateForm.filteredSports.find(
      (filteredSport) => filteredSport.sport_name.toLowerCase().trim() === sportForm.selectedSport.toLowerCase().trim()
    ).max_avail_arenas || 0;

    // Check if increasing the court would exceed the max limit
    if (sportForm.numberOfCourts < max) {
      sportForm.numberOfCourts += 1; // Increase the number of courts
      // No need for an error message here, just update state
    } else {
      showAlert(`Cannot exceed max courts limit of ${max}`); // Show alert for exceeding max limit
    }

    // Update state
    setDateForms(updatedDateForms);

    // Recalculate total price
    recalculateTotalPrice();
  };

  const decreaseCourt = (dateIndex, sportIndex) => {
    const updatedDateForms = [...dateForms];
    const dateForm = updatedDateForms[dateIndex];
    const sportForm = dateForm.sportForms[sportIndex];

    // Decrease court if greater than 0
    if (sportForm.numberOfCourts > 0) {
      sportForm.numberOfCourts -= 1; // Decrease the number of courts
      // No need for an error message here, just update state
    } else {
      showAlert('Court count cannot be less than 0'); // Show alert for minimum court count
    }

    // Update state
    setDateForms(updatedDateForms);

    // Recalculate total price
    recalculateTotalPrice();
  };

  const increaseHours = (dateIndex, sportIndex) => {
    const updatedDateForms = [...dateForms];
    const sportForm = updatedDateForms[dateIndex].sportForms[sportIndex];

    // Check if a sport is selected
    if (!sportForm.selectedSport) {
      showAlert('Please select a sport before increasing hours.'); // Show alert if no sport is selected
      return; // Prevent further execution
    }

    // Check if hours are less than 24 before increasing
    if (sportForm.hours < 24) {
      sportForm.hours += 1; // Increase hours
    } else {
      showAlert('Cannot exceed the maximum limit of 24 hours.'); // Show alert if limit is exceeded
    }

    // Update state
    setDateForms(updatedDateForms);

    // Recalculate total price
    recalculateTotalPrice();
  };

  const decreaseHours = (dateIndex, sportIndex) => {
    const updatedDateForms = [...dateForms];
    const sportForm = updatedDateForms[dateIndex].sportForms[sportIndex];

    // Check if a sport is selected
    if (!sportForm.selectedSport) {
      showAlert('Please select a sport before decreasing hours.'); // Show alert if no sport is selected
      return; // Prevent further execution
    }

    // Decrease hours if greater than 0
    if (sportForm.hours > 0) {
      sportForm.hours -= 1; // Decrease hours
    } else {
      showAlert('Hours cannot be less than 0.'); // Show alert if below minimum limit
    }

    // Update state
    setDateForms(updatedDateForms);

    // Recalculate total price
    recalculateTotalPrice();
  };


  const handleSubmit = async () => {
    for (const dateForm of dateForms) {
      const selectedData = rows.filter(row => row.sport && row.date && row.startTime && row.endTime);
      if (selectedData.length === 0) {
        alert('Please fill all fields in at least one row.'); // Validate selection
        return;
      }
      const bookingData = selectedData.map(row => ({
        center: selectedCenter,
        sport: row.sport,
        date: row.date,
        startTime: row.startTime,
        endTime: row.endTime,
      }));

      console.log('Booking Data:', bookingData);
      if (dateForm.selectionType === 'individual') {
        if (!dateForm.individualName) {
          alert('Please enter your name');
          return;
        }
        if (!dateForm.companyContactNumber || dateForm.companyContactNumber.length !== 10) {
          alert('Please ensure the contact number is exactly 10 digits long');
          return;
        }
      }
      if (dateForm.selectionType === 'company') {
        if (!dateForm.companyName) {
          alert('Please enter the company name');
          return;
        }
        if (!dateForm.companyContactPerson) {
          alert('Please enter the contact person');
          return;
        }
        if (!dateForm.companyContactNumber || dateForm.companyContactNumber.length !== 10) {
          alert('Please ensure the contact number is exactly 10 digits long');
          return;
        }
      }
      if (!dateForm.selectedDate) {
        alert('Please select a date.');
        return;
      }
      if (!dateForm.selectedCenter) {
        alert('Please select a center.');
        return;
      }
      if (dateForm.sportForms.some(sport => !sport.selectedSport || sport.numberOfCourts <= 0)) {
        alert('Please select a sport and specify the number of courts.');
        return;
      }
    }

    const handleSportChange = (event) => {
      const selectedSport = event.target.value;
      const selectedSportData = sports.find(sport => sport.name === selectedSport);
      if (selectedSportData) {
        const unitPrice = selectedSportData.price; // Assuming price is stored in sports data
        const gst = unitPrice * 0.18; // Example GST calculation
        const totalPrice = unitPrice + gst;

        setQuotationData({
          product: selectedSport,
          unitPrice: unitPrice,
          gst: gst,
          totalPrice: totalPrice,
        });

        setShowSummary(true); // Show the summary as soon as a sport is selected
      }
    };
    const numberToWords = (num) => {
      const units = [
        '',
        'One',
        'Two',
        'Three',
        'Four',
        'Five',
        'Six',
        'Seven',
        'Eight',
        'Nine',
        'Ten',
        'Eleven',
        'Twelve',
        'Thirteen',
        'Fourteen',
        'Fifteen',
        'Sixteen',
        'Seventeen',
        'Eighteen',
        'Nineteen',
      ];

      const tens = [
        '',
        '',
        'Twenty',
        'Thirty',
        'Forty',
        'Fifty',
        'Sixty',
        'Seventy',
        'Eighty',
        'Ninety',
      ];

      const scales = ['', 'Thousand', 'Million', 'Billion'];

      const words = (n) => {
        if (n < 20) return units[n];
        if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 > 0 ? ' ' + units[n % 10] : '');
        if (n < 1000) return units[Math.floor(n / 100)] + ' Hundred' + (n % 100 > 0 ? ' ' + words(n % 100) : '');
        for (let i = 0; i < scales.length; i++) {
          const scaleValue = Math.pow(1000, i);
          if (n < scaleValue * 1000) {
            return words(Math.floor(n / scaleValue)) + ' ' + scales[i] + (n % scaleValue > 0 ? ' ' + words(n % scaleValue) : '');
          }
        }
      };

      return words(Math.floor(num)) + ' Rupees'; // Convert only the integer part to words
    };

    const generatePDF = (sportsDetails, totalAmount, addons, uniqueQuotationId, bookingData) => {
      const doc = new jsPDF();
      const img = new Image();

      img.src = pdfimage; // The imported image file

      return new Promise((resolve) => {
        img.onload = function () {
          // Insert the image
          // Increased padding for better spacing
          const imgX = 25; // X position for the image
          const imgY = 30; // Adjusted for image padding
          const imgWidth = 40; // Width of the image
          const imgHeight = 40; // Height of the image

          // Title
          const titleText = 'Quotation';
          doc.setFontSize(20); // Increased font size for the title
          doc.setFont('helvetica', 'bold');
          const titleTextWidth = doc.getTextWidth(titleText);
          const pageWidth = doc.internal.pageSize.getWidth();
          const titleXPosition = (pageWidth - titleTextWidth) / 2; // Centering the title horizontally
          doc.text(titleText, titleXPosition, 25); // Positioning the title

          // Add the image
          doc.addImage(img, 'PNG', imgX, imgY, imgWidth, imgHeight);

          // Company Details
          doc.setFontSize(10);
          doc.setFont('helvetica', 'normal');

          // Position for company details
          const companyDetailsX = imgX + imgWidth + 30; // Start after the image with some padding

          doc.text('Machaxi (Septathlon Services Pvt. Ltd)', companyDetailsX, imgY + 10); // Aligning vertically with the image
          doc.text('# 207, HAL Varthur Main Road, Marathahalli, Spice Garden,', companyDetailsX, imgY + 15);
          doc.text('Purva Riviere, Bengaluru, Karnataka - 560037', companyDetailsX, imgY + 20);
          doc.text('Bangalore Karnataka 560037', companyDetailsX, imgY + 25);
          doc.text('India', companyDetailsX, imgY + 30);
          doc.text('GSTIN 29ABCC3C569K1ZS', companyDetailsX, imgY + 35);



          // Add the simple table (Quotation No., Date, Person/Company Name, Place of Supply)
          const personName = bookingData[0].person || 'N/A'; // Example field for person's name

          // Check if the company name should be included
          const companyName = bookingData[0].isCompany && bookingData[0].organisation ? bookingData[0].organisation : null; // Assign company name only if exists

          const currentDate = new Date().toLocaleDateString(); // Get current date

          // Table data structured for two columns
          const tableData = [
            ['Quotation No.', uniqueQuotationId],
            ['Date', currentDate],
            ['Name', personName],
          ];

          // Conditionally add the company name if it exists
          if (companyName) {
            tableData.push(['Company Name', companyName]);
          }

          tableData.push(['Place of Supply', 'Karnataka(29)']);


          doc.autoTable({
            head: [['Field', 'Details']],
            body: tableData,
            startY: 75, // Position the table after company details
            theme: 'plain',
            styles: {
              fontSize: 10,
              textColor: [0, 0, 0],
            },
            headStyles: {
              fillColor: [22, 160, 133], // Teal color for header
              textColor: [255, 255, 255], // White text color for header
              fontSize: 12,
              fontStyle: 'bold',
            },
            columnStyles: {
              0: { fontStyle: 'bold' }, // Make the first column bold
            },
          });

          // Table for sports details
          doc.autoTable({
            head: [['Sport', 'Unit Price', 'GST', 'Total Cost']],
            body: sportsDetails.map(sportDetail => ([
              sportDetail.sport,
              `Rs ${sportDetail.unit_cost.toFixed(2)}`, // unit_cost formatted as currency
              `${sportDetail.GST}%`,
              `Rs ${sportDetail.final_cost.toFixed(2)}` // final_cost formatted as currency
            ])),
            startY: doc.previousAutoTable.finalY + 10, // Position the sports table below the simple table
            theme: 'grid',
            styles: {
              cellPadding: 5,
              fontSize: 10,
              halign: 'center', // Center-align text in table cells
              textColor: [0, 0, 0], // Set text color
            },
            headStyles: {
              fillColor: [22, 160, 133], // Teal color for table header
              textColor: [255, 255, 255], // White text color for header
              fontSize: 12,
              fontStyle: 'bold',
            },
          });



          // Add-ons section
          const addonsStartY = doc.previousAutoTable.finalY + 10;
          const addonsData = addons.length > 0
            ? addons.map((addon, index) => [index + 1, addon])
            : [['1', 'No Add-ons']];

          doc.autoTable({
            head: [['No.', 'Add-ons']],
            body: addonsData,
            startY: addonsStartY,
            theme: 'grid',
            styles: {
              cellPadding: 5,
              fontSize: 10,
              halign: 'center',

            },
            headStyles: {

              textColor: [255, 255, 255],
              fontSize: 12,
              fontStyle: 'bold',
            },
          });

          // Total label and amount
          const totalY = doc.previousAutoTable.finalY + 10;
          doc.setFontSize(12);
          doc.setFont('helvetica', 'bold');
          doc.text('Total:', 20, totalY);
          doc.setFontSize(12);
          doc.setFont('helvetica', 'normal');
          doc.text(`Rs ${totalAmount.toFixed(2)}`, 50, totalY); // Amount formatted as currency

          // Total in words
          const totalInWords = numberToWords(totalAmount);
          doc.setFontSize(12);
          doc.setFont('helvetica', 'italic');
          doc.text(`Total in Words: ${totalInWords}`, 20, totalY + 10);

          // Terms & Conditions Header
          const termsYStart = totalY + 30;
          doc.setFontSize(12);
          doc.setFont('helvetica', 'bold');
          doc.text('Terms & Conditions', 20, termsYStart);

          doc.setFontSize(10); // Set font size for body text
          doc.setFont('helvetica', 'normal');

          // Define the Terms & Conditions text
          const termsText = [
            "Thank you for considering Machaxi for hosting your corporate tournament. Please take a moment to review our booking policies to ensure a seamless experience:",
            "1. Quotation and Availability",
            "   - The provided quotation is for inquiry purposes only and does not guarantee slot or service availability. Slot confirmation will be communicated by our executives through your preferred mode of contact—via phone, WhatsApp, or email.",
            "   - Add-on services are excluded from the initial quotation and will be included in the final invoice upon confirmation from our executives and partnered vendors.",
            "2. Food Services Policy",
            "   - Outside food services are strictly prohibited. Exceptions may be made for certain Machaxi venues, but at an additional cost. It is the customer's responsibility to inform us about external food arrangements before confirming the event or making any payments. Machaxi reserves the right to deny external food services if not pre-approved, and no refunds will be issued in such cases.",
            "3. Cancellation Policy",
            "   - More than 10 days in advance: No cancellation fees apply.",
            "   - 5-10 days in advance: A 20% cancellation fee will be charged based on the total invoice amount.",
            "   - 3-5 days in advance: A 50% cancellation fee will apply.",
            "   - Less than 3 days in advance: The full invoice amount will be charged as a penalty.",
            "4. Rescheduling Policy",
            "   - Rescheduling of the event can be requested up to 7 days before the tournament date. Any rescheduling requests made after this period may not be accommodated.",
            "5. Add-on Services",
            "   - Cancellation or rescheduling of any confirmed add-on services (e.g., equipment, catering, etc.) is not permitted. The full invoice amount for these services will be charged regardless of cancellation.",
            "We appreciate your understanding and cooperation in adhering to these terms. Should you have any questions or require further clarification, please feel free to contact us."
          ];

          // Define the maximum width for the text (in mm)
          const maxWidth = 180; // Adjust according to your page width
          let currentYPosition = termsYStart + 10; // Initialize current Y position, added spacing below the title
          const pageHeight = doc.internal.pageSize.height; // Get the height of the page

          // Function to handle text rendering with proper alignment for bullet points and regular text
          const renderTextWithAlignment = (text) => {
            const splitLines = doc.splitTextToSize(text, maxWidth);
            splitLines.forEach((splitLine) => {
              // Check if the current Y position exceeds the page height
              if (currentYPosition + 10 > pageHeight) { // 10 for margin
                doc.addPage(); // Add a new page
                // Re-add the title and adjust current position
                // doc.setFontSize(12);
                // doc.setFont('helvetica', 'bold');
                // doc.text('Terms & Conditions', 20, 20); // Re-add the title at the top of the new page
                currentYPosition = 20; // Reset Y position for the new page
              }

              doc.setFontSize(10); // Ensure font size is set to 10 for body text
              doc.setFont('helvetica', 'normal');

              // Align bullet points starting with '-' to the left (no indentation)
              if (text.startsWith("   -")) {
                doc.text(splitLine, 20, currentYPosition); // Left align the first line of bullet points
              } else if (text.startsWith("1.") || text.startsWith("2.") || text.startsWith("3.") || text.startsWith("4.") || text.startsWith("5.")) {
                doc.text(splitLine, 20, currentYPosition); // Align numbers to the left
              } else {
                doc.text(splitLine, 20, currentYPosition); // Regular text without modification
              }

              currentYPosition += 7; // Adjust the line spacing for better readability
            });
          };

          // Add each line of the Terms & Conditions
          termsText.forEach((line) => {
            renderTextWithAlignment(line);
          });

          const stampImg = new Image();
          stampImg.src = stamp; // The imported image file
          const stampWidth = 48; // Set your desired width for the stamp image
          const stampHeight = 45; // Set your desired height for the stamp image
          const stampX = doc.internal.pageSize.getWidth() - stampWidth - 20; // Right-aligned position
          const stampY = currentYPosition + 10; // Positioning below terms & conditions with some spacing

          doc.addImage(stampImg, 'PNG', stampX, stampY, stampWidth, stampHeight); // Add the stamp image

          // Add "Authorized Signature" text below the stamp image
          const signatureText = 'Authorized Signature';
          const signatureTextWidth = doc.getTextWidth(signatureText); // Calculate the width of the signature text
          const signatureX = stampX + (stampWidth - signatureTextWidth) / 2; // Center the text under the stamp

          const signatureY = stampY + stampHeight + 5; // Position below the stamp with some spacing

          // Set font properties and add the text
          doc.setFontSize(12);
          doc.setFont('helvetica', 'normal');
          doc.text(signatureText, signatureX, signatureY);


          // Save the generated PDF
          doc.save('quotation.pdf');

          // Generate the PDF as a blob
          const pdfBlob = doc.output('blob');

          // Resolve the promise with the blob
          resolve(pdfBlob);
        };

        img.onerror = function () {
          // Handle image load error
          resolve(null);
        };
      });
    };

    const bookingData = dateForms.map(dateForm => ({
      person: dateForm.selectionType === 'company' ? dateForm.companyContactPerson : dateForm.individualName,
      organisation: dateForm.selectionType === 'company' ? dateForm.companyName : '',
      date_of_tournament: dateForm.selectedDate,
      sports: dateForm.sportForms.map(sportForm => ({
        sport: sportForm.selectedSport,
        no_of_arenas: sportForm.numberOfCourts,
        no_of_hours: sportForm.hours,
      })),
      centre: dateForm.selectedCenter,
      contact_number: dateForm.companyContactNumber,
      addons: addonFormData,
      isCompany: dateForm.selectionType === 'company',
    }));

    try {
      // console.log(bookingData);

      // Send the booking data to the backend
      const quotation = await axios.post('https://admin.machaxi.com/summer-api/get-quotations', bookingData, {
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
        }
      });


      // console.log("Quotation received:", quotation.data.finalCostDetails);

      // Ensure that the data array is properly accessed
      if (quotation.data && Array.isArray(quotation.data.finalCostDetails)) {
        const sportsDetails = quotation.data.finalCostDetails[0]; // Sports details array
        // console.log("The sports details are : ", sportsDetails);
        const totalAmount = quotation.data.finalCostDetails[1]; // Total Amount
        // console.log("The total amount is : ", totalAmount);
        const addons = quotation.data.finalCostDetails[2]; // Add-ons
        // console.log("The addons are : ", addons);

        const generateQuotationId = () => {
          const timestamp = Date.now(); // Current timestamp in milliseconds
          const randomNum = Math.floor(Math.random() * 10000); // Random number
          return `QTN-${timestamp}-${randomNum}`; // Example format: QTN-1632492011123-4567
        };

        // Generate a unique quotation ID
        const uniqueQuotationId = generateQuotationId();
        console.log(uniqueQuotationId);

        const pdfBlob = await generatePDF(sportsDetails, totalAmount, addons, uniqueQuotationId, bookingData);
        if (!pdfBlob) {
          alert('Failed to generate PDF. Please try again.');
          return; // Exit the function if PDF generation failed
        }
        // Generate the PDF


        // Convert PDF Blob to Base64 to send it to the backend
        const pdfBase64 = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(pdfBlob);
          reader.onloadend = () => {
            resolve(reader.result.split(',')[1]); // Get the base64 part
          };
        });

        //Generate the unique Id for quotation


        // Create a new booking data array with the PDF added to the first entry
        const bookingDataWithPDF = bookingData.map((entry, index) => ({
          ...entry, // Keep the rest of the bookingData intact
          pdf: index === 0 ? pdfBase64 : undefined, // Add the PDF only to the first booking entry
          uniquequotationId: uniqueQuotationId,

        }));
        console.log(bookingDataWithPDF)

        // Send the modified booking data with the PDF to the backend
        const response = await axios.post('https://admin.machaxi.com/summer-api/user-post-data', bookingDataWithPDF, {
          headers: {
            'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
          },
          timeout: 10000
        });

        if (response.status === 200) {
          alert('Quotation Generated');
          // console.log('Bookings created successfully:', response.data);
        }
        // else {
        //   // console.error('Error creating bookings:', response.data.message);
        // }
      }
      // else {
      //   // console.error('Quotation data is not in the expected array format:', quotation);
      // }
    } catch (error) {
      // Handle any errors
      // console.error('Error during booking:', error.response?.data?.message || error.message);

      if (error.response) {
        alert('Error during booking: ' + (error.response.data.message));
      }
      else {
        alert('Quotation Generated');
      }
    }
  };



  const [removedSports, setRemovedSports] = useState([]);

  const handleRemoveSport = (dateIndex, sportIndex) => {
    const updatedDateForms = [...dateForms];
    const removedSport = updatedDateForms[dateIndex].sportForms[sportIndex].selectedSport;
    const removedHours = updatedDateForms[dateIndex].sportForms[sportIndex].hours;
    const removedNumberOfCourts = updatedDateForms[dateIndex].sportForms[sportIndex].numberOfCourts;

    // Remove the sport from the sportForms array
    updatedDateForms[dateIndex].sportForms.splice(sportIndex, 1);
    setDateForms(updatedDateForms);

    // Remove the sport from the removedSports array to allow selecting it again
    setRemovedSports(prev => prev.filter(sport => sport !== removedSport.toLowerCase()));

    // Update the quotation data state
    setQuotationData(prev => {
      const updatedProducts = prev.products.map(product => {
        if (product.product.toLowerCase() === removedSport.toLowerCase()) {
          // Deduct the hours of the removed sport from the total hours
          const updatedHours = product.hours - removedHours;
          const updatedTotalPrice = product.totalPrice - (product.unitPrice + (product.unitPrice * (product.gst / 100))) * removedNumberOfCourts * removedHours;

          // If the updated hours are zero or less, remove the product entry from the quotationData state
          if (updatedHours <= 0) {
            return null;
          }

          return {
            ...product,
            hours: updatedHours,
            totalPrice: updatedTotalPrice,
          };
        }

        return product;
      }).filter(product => product !== null); // Filter out null products

      // If no products are left, return an empty array
      if (updatedProducts.length === 0) {
        return { products: [] };
      }

      return { products: updatedProducts };
    });

    // Check if all available sports are selected
    const selectedSports = updatedDateForms[dateIndex].sportForms.map(form => form.selectedSport.toLowerCase());
    const availableSports = filteredSports.map(sport => sport.sport_name.toLowerCase());

    // If fewer sports are selected than available, show the "Add Another Sport" button
    if (selectedSports.length < availableSports.length) {
      setShowAddAnotherSportButton(true);
    }

    // Optionally, clear any error messages or update other states if needed
    setErrorMessage(''); // Clear previous error message
  };

  const [isPickerVisible, setPickerVisible] = useState(false);

  const timeSlots = [
    '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM'
  ];
  useEffect(() => {
    if (selectedSport && selectedCenter) {
      const selectedSportData = filteredSports.find(
        sport => sport.sport_name.toLowerCase() === selectedSport.toLowerCase()
      );
      if (selectedSportData) {
        const max = selectedSportData.max_avail_arenas || 0;
        console.log("Max Courts for Selected Sport:", max); // Log max courts
        setMaxCourts(max);
        setNumberOfCourts(Math.min(numberOfCourts, max));
      } else {
        console.log("No sport data found for:", selectedSport); // Log if no data is found
        setMaxCourts(0);
        setNumberOfCourts(0);
      }
    }
  }, [selectedSport, selectedCenter, filteredSports]);

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    if (field === 'sport') {
      console.log("Selected Sport:", value); // Log selected sport
      const selectedSportData = filteredSports.find(sport => sport.sport_name === value);
      if (selectedSportData) {
        // Set the default number of courts to 1
        updatedRows[index].courts = 1;

        // Update maxCourts for the selected sport
        const maxArenas = selectedSportData.max_avail_arenas || 0;
        updatedRows[index].maxArenas = maxArenas;
      }
    }

    if (field === 'startTime') {
      updatedRows[index].endTime = ''; // Reset endTime when startTime changes
    }

    console.log("Updated Rows:", updatedRows); // Log updated rows
    setRows(updatedRows);
  };
  console.log("Max Available Arenas for All Sports:");
  filteredSports.forEach(sport => {
    console.log(`Sport: ${sport.sport_name}, Max Arenas: ${sport.max_avail_arenas}`);
  });
  const handleAddRow = () => {
    setRows([...rows, { sport: '', date: '', startTime: '', endTime: '' }]);
  };

  // Remove a row from the table (except the first row)
  const handleRemoveRow = (index) => {
    if (index === 0) return; // Prevent removing the first row
    const updatedRows = rows.filter((_, i) => i !== index); // Remove the row at the specified index
    setRows(updatedRows);
  };
  const getEndTimeOptions = (startTime) => {
    if (!startTime) return timeSlots;
    const startIndex = timeSlots.indexOf(startTime);
    return timeSlots.slice(startIndex + 1);
  };
  const [showCenterInfoModal, setShowCenterInfoModal] = useState(false);

  const CenterInfoModal = ({ centers, sports, onClose }) => {
    return (
      <div className="center-info-modal-overlay">
        <div className="center-info-modal-content">
          <h2 className="center-info-modal-title">Centers and Their Sports</h2>
          <button className="center-info-modal-close-button" onClick={onClose}>×</button>
          <div className="center-info-modal-list">
            {centers.map((center, index) => (
              <div key={index} className="center-info-modal-item">
                <h3 className="center-info-modal-center-name">{center}</h3>
                <ul className="center-info-modal-sports-list">
                  {sports
                    .filter(sport => sport.academy_name === center)
                    .map((sport, i) => (
                      <li key={i} className="center-info-modal-sport-item">{sport.sport_name}</li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="buyplan-container">
      <Navbar />
      <div className="navbar-spacing"></div>
      <div className="buyplan-left">
        <h2 className="heading">⁠Get Quotation for Hosting Tournament at Machaxi</h2>

        {dateForms.map((dateForm, dateIndex) => (
          <div key={dateIndex} className="buyplan-form">
            {dateIndex === 0 && (
              <div className="selection-type">
                <label className="radio-label">
                  <input
                    type="radio"
                    value="company"
                    checked={dateForm.selectionType === 'company'}
                    onChange={(e) => handleDateFormChange(dateIndex, 'selectionType', e.target.value)}
                  />
                  Company
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    value="individual"
                    checked={dateForm.selectionType === 'individual'}
                    onChange={(e) => handleDateFormChange(dateIndex, 'selectionType', e.target.value)}
                  />
                  Individual
                </label>
              </div>
            )}
            {/* Other form fields go here */}


            {/* Conditional Inputs for Company */}
            {dateForm.selectionType === 'company' && dateIndex === 0 && (
              <div className="company-inputs">
                <label className="label">Company Name:</label>
                <input
                  type="text"
                  value={dateForm.companyName}
                  onChange={(e) => handleDateFormChange(dateIndex, 'companyName', e.target.value)}
                  className="input-text"
                />

                <label className="label">Contact Person:</label>
                <input
                  type="text"
                  value={dateForm.companyContactPerson}
                  onChange={(e) => {
                    // Regular expression to allow only alphabetic characters and spaces
                    const inputValue = e.target.value;
                    if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                      handleDateFormChange(dateIndex, 'companyContactPerson', inputValue);
                    }
                  }}
                  className="input-text"
                />


                <label className="label">Contact Number:</label>
                <input
                  type="text"
                  value={dateForm.companyContactNumber}
                  onInput={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                    if (value.length <= 10) { // Allow only up to 10 digits
                      handleDateFormChange(dateIndex, 'companyContactNumber', value);
                    } else {
                      e.preventDefault(); // Prevent more than 10 digits from being entered
                    }
                  }}
                  className="input-text"
                />
              </div>
            )}

            {/* Conditional Inputs for Individual */}
            {dateForm.selectionType === 'individual' && dateIndex === 0 && (
              <div className="individual-inputs">
                <label className="label">Name:</label>
                <input
                  type="text"
                  value={dateForm.individualName}
                  onChange={(e) => handleDateFormChange(dateIndex, 'individualName', e.target.value)}
                  className="input-text"
                />

                <label className="label">Contact Number:</label>
                <input
                  type="text"
                  value={dateForm.companyContactNumber}
                  onInput={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                    if (value.length <= 10) { // Allow only up to 10 digits
                      handleDateFormChange(dateIndex, 'companyContactNumber', value);
                    } else {
                      e.preventDefault(); // Prevent more than 10 digits from being entered
                    }
                  }}
                  className="input-text"
                />
              </div>
            )}
            <div className="multiple-event-message">For multiple event dates, click on Add Another Date at the bottom</div>

            <div className="center-selection">
              <label className="label">Select Center:<span className="info-icon" onClick={() => setShowCenterInfoModal(true)}>i</span></label>
              <select
                value={selectedCenter}
                onChange={handleCenterChange}
                className="center-dropdown"
              >
                <option value="">-- Select --</option>
                {centers.map((center, index) => (
                  <option key={index} value={center}>{center}</option>
                ))}
              </select>

            </div>
            {showCenterInfoModal && (
              <CenterInfoModal
                centers={centers}
                sports={sports}
                onClose={() => setShowCenterInfoModal(false)}
              />
            )}

            {selectedCenter && (
              <div>
                <div className="sport-time-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Sport</th>
                        <th>Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Arena</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row, index) => {
                        const endTimeOptions = getEndTimeOptions(row.startTime);

                        // Find the selected sport's data from filteredSports
                        const selectedSportData = filteredSports.find(
                          sport => sport.sport_name.toLowerCase() === row.sport.toLowerCase()
                        );

                        // Get max_avail_arenas for the selected sport
                        const maxArenas = selectedSportData ? selectedSportData.max_avail_arenas : 0;

                        return (
                          <tr key={index} className="table-row">
                            <td className="table-cell">
                              <select
                                value={row.sport}
                                onChange={(e) => handleRowChange(index, 'sport', e.target.value)}
                                className="sport-dropdown"
                              >
                                <option value="">-- Select --</option>
                                {filteredSports.map((sport, i) => (
                                  <option key={i} value={sport.sport_name}>{sport.sport_name}</option>
                                ))}
                              </select>
                            </td>
                            <td className="table-cell">
                              <DatePicker
                                selected={row.date ? new Date(row.date) : null}
                                onChange={(date) => handleRowChange(index, 'date', date)}
                                dateFormat="dd/MM/yyyy"
                                className="custom-date-picker"
                                minDate={new Date()}
                                placeholderText="Select Date"
                                dropdownMode="select"
                                onKeyDown={(e) => e.preventDefault()}
                              />
                            </td>
                            <td className="table-cell">
                              <select
                                value={row.startTime}
                                onChange={(e) => handleRowChange(index, 'startTime', e.target.value)}
                                className="time-dropdown"
                              >
                                <option value="">-- Select --</option>
                                {timeSlots.map((time, i) => (
                                  <option key={i} value={time}>{time}</option>
                                ))}
                              </select>
                            </td>
                            <td className="table-cell">
                              <select
                                value={row.endTime}
                                onChange={(e) => handleRowChange(index, 'endTime', e.target.value)}
                                className="time-dropdown"
                                disabled={!row.startTime}
                              >
                                <option value="">-- Select --</option>
                                {endTimeOptions.map((time, i) => (
                                  <option key={i} value={time}>{time}</option>
                                ))}
                              </select>
                            </td>
                            <td className="table-cell">
                              {row.sport && maxArenas > 0 ? (
                                <select
                                  value={row.courts}
                                  onChange={(e) => handleRowChange(index, 'courts', parseInt(e.target.value))}
                                  className="court-dropdown"
                                >
                                  {Array.from({ length: maxArenas }, (_, i) => (
                                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                                  ))}
                                </select>
                              ) : (
                                <span>1</span>
                              )}
                            </td>
                            <td className="table-cell">
                              {index !== 0 && (
                                <button onClick={() => handleRemoveRow(index)} className="remove-row-button">
                                  Remove
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <button onClick={handleAddRow} className="add-row-button">
                  + Add Row
                </button>
              </div>
            )}

          </div>
        ))}

        <AddonsSection onAddOnFormChange={handleAddOnFormChange} />

        {dateForms[0]?.selectedCenter && (
          <div className="dynamic-info">
            <h3>Contact Information:</h3>
            <p>Helpline: {centerInfo[dateForms[0].selectedCenter]?.helpline || 'N/A'}</p>
            <p>
              <a
                href={centerInfo[dateForms[0].selectedCenter]?.location}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Location on Google Maps
              </a>
            </p>
          </div>
        )}

        <div >
          <button className="submit-button hide-on-mobile" onClick={openInNewTab}>Generate Another Quotation</button>
        </div>
      </div>

      <div className="quotation-summary">
        <QuotationSummary
          products={quotationData.products.map(product => ({
            ...product,
            gstPrice: Math.round(product.gstAmount ?? 0),
            totalPrice: Math.round(product.totalPrice ?? 0),
          }))}
          selectedAddOns={quotationData.selectedAddOns}
          handleSubmit={handleSubmit}

        />
        <div className='another-quotation-container'>
          <button className="submit-button" onClick={openInNewTab}>
            Generate Another Quotation
          </button>
        </div>

      </div>

    </div>

  );
};
export default BuyPlan;