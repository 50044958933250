import { useEffect, useRef, useState } from 'react';
import '../style/addon.css';

const BuyPlan = ({ onAddOnFormChange }) => {
  const sportSliderRef = useRef(null);
  
  const [availableAddOns, setAvailableAddOns] = useState([]);
  const [addOnDescriptions, setAddOnDescriptions] = useState({});
  const [addOnPrices, setAddOnPrices] = useState({});
  const [addOnGstRates, setAddOnGstRates] = useState({}); // New state for GST rates
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  
  useEffect(() => {
    const fetchAddOns = async () => {
      try {
        const response = await fetch('https://admin.machaxi.com/summer-api/get-data', {
          headers: {
            'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
          }
        });
        const data = await response.json();
  
        if (data.allAddons && Array.isArray(data.allAddons)) {
          const addons = data.allAddons;
          const formattedAddOns = addons.map(addon => addon.addon_name).filter(Boolean);
          const descriptions = {};
          const prices = {};
          const gstRates = {}; // Store GST rates for each add-on

          addons.forEach(addon => {
            if (addon.addon_name) {
              descriptions[addon.addon_name] = addon.description;
              prices[addon.addon_name] = Number(addon.unit_price) || 0; // Ensure the price is a number
              gstRates[addon.addon_name] = Number(addon.GST) || 0; // Store GST rate for each add-on
            }
          });
  
          setAvailableAddOns(formattedAddOns);
          setAddOnDescriptions(descriptions);
          setAddOnPrices(prices);
          setAddOnGstRates(gstRates); // Set GST rates state
        } else {
          console.error('Invalid add-ons data from the backend.');
        }
      } catch (error) {
        console.error('Error fetching add-ons:', error);
      }
    };
  
    fetchAddOns();
  }, []);
  
  const handleAddOnSelection = (addOn) => {
    if (addOn && !selectedAddOns.includes(addOn)) {
      const updatedSelectedAddOns = [...selectedAddOns, addOn];
      setSelectedAddOns(updatedSelectedAddOns);
      onAddOnFormChange(updatedSelectedAddOns); // Update the parent component
    }
  };

  // Function to handle removal of an add-on
  const handleRemoveAddOn = (addOn) => {
    const updatedSelectedAddOns = selectedAddOns.filter(selected => selected !== addOn);
    setSelectedAddOns(updatedSelectedAddOns);
    onAddOnFormChange(updatedSelectedAddOns); // Update the parent component
  };
  
  // State for the dropdown selection
  const [dropdownValue, setDropdownValue] = useState("");

  // Handle dropdown change
  const handleDropdownChange = (event) => {
    const selectedAddOn = event.target.value;
    setDropdownValue(""); // Reset dropdown value after selection
    handleAddOnSelection(selectedAddOn);
  };
  
  return (
    <div className="addons-section">
      <h3 className="addons-heading">Choose Add-on Services</h3>

      {/* Conditionally render dropdown based on the number of selected add-ons */}
      {selectedAddOns.length < availableAddOns.length && (
        <div className="custom-dropdown">
          <select value={dropdownValue} onChange={handleDropdownChange} className="dropdown">
            <option value="" disabled>
              {selectedAddOns.length > 0 ? "Add More" : "Select an add-on"}
            </option>
            {availableAddOns.filter(addOn => !selectedAddOns.includes(addOn)).map((addOn) => (
              <option key={addOn} value={addOn}>
                {addOn}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="selected-addons">
        {selectedAddOns.map((addOn) => {
          const price = addOnPrices[addOn] || 0;
          const gstRate = addOnGstRates[addOn] || 0; // Get GST rate for the add-on
          const gstAmount = (price * gstRate) / 100; // Calculate GST amount
          const totalAmount = price + gstAmount; // Calculate total amount

          return (
            <div key={addOn} className="addon-option">
              <span className="addon-label">{addOn}:</span>
              <p className="addon-description">{addOnDescriptions[addOn]}</p>
              <div className="addon-details">
                <p className="addon-price">
                  Price: ₹{typeof price === 'number' ? price.toFixed(2) : 'N/A'}
                </p>
                <p className="addon-gst">
                  GST ({gstRate}%): ₹{gstAmount.toFixed(2)}
                </p>
                <p className="addon-total">
                  Total: ₹{totalAmount.toFixed(2)}
                </p>
              </div>
              <button onClick={() => handleRemoveAddOn(addOn)} className="remove-addon-button" style={{ marginLeft: '10px', cursor: 'pointer', color: 'red' }}>-</button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BuyPlan;